import React from "react"
import Layout from "../components/layout"

const TrainingPage = () => (
  <Layout>
    <h2>Om opplæringen</h2>
    <p>
      Den 1. januar 2005 ble det innført nye regler for føreropplæringen. Den
      nye opplæringen legger særlig vekt på å påvirke elevenes handlinger og
      atferd. Føreropplæringen er delt opp i fire opplæringstrinn og skal sikre
      eleven trygg og riktig opplæring.
    </p>
    <p>
      Etter både andre og tredje trinn i opplæringen er det lagt inn
      obligatorisk trinnvurdering. Her skal lærer og elev ta stilling til om
      opplæringsmålene er nådd. Trinnvurderingen skal bidra til at kvaliteten
      på og omfanget av øvingen øker, og at opplæringen blir mer strukturert og
      effektiv.
    </p>
    <p>
      Det er mulighet for privat øvingskjøring, men det er da viktig å ta
      kontakt med en trafikkskole så tidlig som mulig, slik at man sammen kan
      utarbeide et opplæringsløp tilpasset eleven og dens forutsetninger.
    </p>
    <br />
    <br />
    <p>
      <h3>Føreropplæringens oppbygging</h3>
    </p>
    <div className={"opplaeringstrinn"}>
      <h4>1. Trafikalt grunnkurs</h4>
      <p>
        Før man begynner å øvelseskjøre, må man ha gjennomført trafikalt
        grunnkurs. Dette er obligatorisk for alle under 25 år.
      </p>
    </div>

    <br />

    <div className={"opplaeringstrinn"}>
      <h4>2. Grunnleggende opplæring</h4>
      <p>
        Her vil du lære å beherske bilen i lite trafikerte områder. Det er
        viktig å ha god kontroll på bilen før man begir seg ut i tyngre trafikk,
        med mange trafikanter man må ta hensyn til. Du vil også få kunnskap om
        blant annet mennesket i trafikken, miljøvennlig kjøring og det ansvaret
        du som fører har i trafikken.
      </p>
    </div>

    <br />

    <div className={"opplaeringstrinn"}>
      <h4>3. Trafikal del</h4>
      <p>
        Her vil hovedfokuset være å mestre variert trafikk, med fokus på andre
        trafikanter. Du skal kunne kjøre sikkert og selvstendig.
      </p>
    </div>

    <br />

    <div className={"opplaeringstrinn"}>
      <h4>4. Avsluttende opplæring</h4>
      <p>
        Nå er du inne i det siste trinnet av opplærinen som vil gjøre deg i
        stand til å kunne kjøre på egenhånd. Risikoforståelse og samhandling med
        andre, er noe av det du vil lære.
      </p>
    </div>

    <br />

    <div className={"opplaeringstrinn"}>
      <h4>Teoriprøve</h4>
      <p>
        Teoriprøven gjennomføres på en trafikkstasjon. Den må være bestått før
        du kan kjøre opp.
      </p>
    </div>

    <br />

    <div className={"opplaeringstrinn"}>
      <h4>Oppkjøring</h4>
      <p>Etter bestått oppkjøring får du utstedt førekort.</p>
    </div>

    <br />
    <br />
    <p>
        Hos Statens Vegvesen kan du lese mer om&nbsp;
        <a
            href="https://www.vegvesen.no/fag/trafikk/godkjenning-og-tilsyn-med-trafikkopplaring/forskrifter-og-laereplaner/"
            target="_blank"
            rel="noreferrer"
        >opplæringen</a>
        , og du finner også&nbsp;
        <a
            href="https://www.vegvesen.no/fag/trafikk/godkjenning-og-tilsyn-med-trafikkopplaring/forskrifter-og-laereplaner/"
            target="_blank"
            rel="noreferrer"
        >forskrifter og læreplaner</a>
        .
    </p>
  </Layout>
)

export default TrainingPage
